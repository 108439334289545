import { faSailboat, faShip} from '@fortawesome/free-solid-svg-icons';
export const corsiPatenteNautica = {
    title: "I nostri corsi per patenti nautiche",
    subtitle: "Presso l'Autoscuola Faro a Pisa è possibile seguire corsi di teoria e di pratica per conseguire la patente nautica per comando di natanti e imbarcazioni da diporto:",
    list: [
        {
            id: "pat1",
            title: "Patente Nautica entro 12 Miglia",
            link: "/patenti-nautiche/patente-nautica-12-miglia",
            icon: faSailboat,
        },
        {
            id: "pat2",
            title: "Patente Nautica no limit",
            link: "/patenti-nautiche/patente-nautica-no-limit",
            icon: faShip,
        }


    ],
    buttonText: null
}