import React from "react";
import Layout from "../components/layout/Layout";
import ListFeature from "../components/ListFeature";
import { corsiPatenteNautica } from "../data/corsi-patente-nautica/corsi-patente-nautica";
import ContactSection from "../components/home-page/ContactSection";
import Seo from "../components/layout/SEO";

const CorsiPatenteNautica = () => {
  return (
    <Layout>
      <Seo 
        title={"Corsi per Patenti Nautiche | Autoscuola Faro, Pisa"}
        description={"Prendi la patente nautica alla nostra autoscuola, con lezioni teoriche e guide in mare in barca nella zona di Pisa."}
        keywords={[
          "corsi patente nautica entro 12 miglia toscana",
          "corsi patenti nautiche toscana",
          "rinnovo patente nautica pisa",
          "scuola nautica Pisa",
          "lezioni patente nautica italiana pisa"
        ]} />
      <ListFeature list={corsiPatenteNautica} />
      <ContactSection />
    </Layout>
  );
};

export default CorsiPatenteNautica;
